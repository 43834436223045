h1, h2 {
	font-weight: 600 !important;
}

.post-content h2 {
	font-size: 22px !important;
}
.center-image {
    margin: 0 auto;
    display: block;
}
img + em {
    margin-top: 4px;
	display: block;
	text-align: center;
	font-style: normal; 
	font-size: smaller;
}